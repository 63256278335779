<template>
  <b-card class="px-2 py-1 mb-1">
    <b-container class="mb-3 rounded border">
      <b-card class="rounded">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <h1 class="font-large-1 m-0">YOUR LOGPOINTS</h1>
          <b-card-text class="h1 text-warning font-large-3 m-0">
            LP {{ balance }}
          </b-card-text>
          <b-card-text class="font-small-3 mt-2" v-if="this.balance < 500">
            Your logpoints are running low!
          </b-card-text>
        </div>
      </b-card>
    </b-container>

    <b-container class="mb-3 rounded border">
      <b-card class="rounded">
        <div class="d-flex justify-content-center align-items-center">
          <h1 class="h1 font-large-2">PACKAGES</h1>
        </div>
      </b-card>
    </b-container>
    <b-container>
      <div sm="8" md="6" lg="12" class="d-flex row justify-content-between">
        <Package
          v-for="(packageItem, index) in packageList"
          :key="index"
          :packageItem="packageItem"
        />
      </div>
    </b-container>
  </b-card>
</template>

<script>
import { BCard, BCardText, BContainer, BLink, BCol, BRow } from "bootstrap-vue";
import Package from "./Package.vue";

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BContainer,
    BLink,
    BRow,
    Package,
  },

  data() {
    return {
      packageList: [
        {
          label: "SILVER",
          value: "1000.00",
          text: "Starters",
          type: "silver",
        },
        {
          label: "GOLD",
          value: "10000.00",
          text: "Growing Business",
          type: "gold",
        },
        {
          label: "PLATINUM",
          value: "15000.00",
          text: "Heavy Load Delivery Corporates",
          type: "platinum",
        },
      ],
      balance: "",
    };
  },

  mounted() {
    this.$http.get(`getRemainingLogpoint`).then((response) => {
      if (response.status === 200) {
        this.balance = response.data.balance;
      }
    });
  },
};
</script>

<style scoped>
.border {
  border-width: 3px !important;
}
</style>
